var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sideContents"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/item/santa2024_standard/"},nativeOn:{"click":function($event){_vm.scrollToTop();
          _vm.$parent.mnvisible = false;}}},[_c('img',{attrs:{"src":require("../assets/images/bnr_submit.png"),"alt":""}})])],1),_c('li',{staticClass:"green"},[_c('router-link',{attrs:{"to":"/informations/guide"},nativeOn:{"click":function($event){_vm.scrollToTop();
          _vm.$parent.mnvisible = false;}}},[_vm._v("ご利用ガイド")])],1),_c('li',{staticClass:"mn"},[_c('router-link',{attrs:{"to":"/status"},nativeOn:{"click":function($event){_vm.scrollToTop();
          _vm.$parent.mnvisible = false;}}},[_vm._v("お申込み状況の確認")])],1),_c('li',{staticClass:"mn"},[_c('router-link',{attrs:{"to":"/informations/about"},nativeOn:{"click":function($event){_vm.scrollToTop();
          _vm.$parent.mnvisible = false;}}},[_vm._v("サンタクロースからの手紙って？")])],1),_c('li',{staticClass:"mn"},[_c('router-link',{attrs:{"to":"/informations/safety"},nativeOn:{"click":function($event){_vm.scrollToTop();
          _vm.$parent.mnvisible = false;}}},[_vm._v("サンタクロース大使館は"),_c('br'),_vm._v("安心安全を追求します")])],1),_c('li',{staticClass:"mn"},[_c('router-link',{attrs:{"to":"/informations/history"},nativeOn:{"click":function($event){_vm.scrollToTop();
          _vm.$parent.mnvisible = false;}}},[_vm._v("過去の封筒・便箋ギャラリー")])],1),_c('li',{staticClass:"mn"},[_c('router-link',{attrs:{"to":"/informations/meetsanta"},nativeOn:{"click":function($event){_vm.scrollToTop();
          _vm.$parent.mnvisible = false;}}},[_vm._v("サンタクロースを呼ぼう！")])],1),_c('li',{staticClass:"mnhd"},[_vm._v("お困りですか？")]),_c('li',{},[_c('router-link',{attrs:{"to":"/informations/qa"},nativeOn:{"click":function($event){_vm.scrollToTop();
          _vm.$parent.mnvisible = false;}}},[_c('img',{attrs:{"src":"/img/bn_contact.e489dcae.png","alt":"お問合せ・Q＆A"}})])],1),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mbnr"},[_c('a',{attrs:{"href":"https://ultramanmail.com/","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/images/bnr_ul.png"),"alt":""}})]),_c('p',[_vm._v(" 【姉妹サイト】 みんなのヒーロー歴代ウルトラマンからの手紙も好評受付中です ")])])
}]

export { render, staticRenderFns }