<template>
  <div class="sideContents">
    <ul>
      <li>
        <router-link
          @click.native="
            scrollToTop();
            $parent.mnvisible = false;
          "
          to="/item/santa2024_standard/"
          ><img src="../assets/images/bnr_submit.png" alt=""
        /></router-link>
      </li>
      <li class="green">
        <router-link
          @click.native="
            scrollToTop();
            $parent.mnvisible = false;
          "
          to="/informations/guide"
          >ご利用ガイド</router-link
        >
      </li>
      <li class="mn">
        <router-link
          @click.native="
            scrollToTop();
            $parent.mnvisible = false;
          "
          to="/status"
          >お申込み状況の確認</router-link
        >
      </li>
      <li class="mn">
        <router-link
          @click.native="
            scrollToTop();
            $parent.mnvisible = false;
          "
          to="/informations/about"
          >サンタクロースからの手紙って？</router-link
        >
      </li>
      <li class="mn">
        <router-link
          @click.native="
            scrollToTop();
            $parent.mnvisible = false;
          "
          to="/informations/safety"
          >サンタクロース大使館は<br />安心安全を追求します</router-link
        >
      </li>
      <li class="mn">
        <router-link
          @click.native="
            scrollToTop();
            $parent.mnvisible = false;
          "
          to="/informations/history"
          >過去の封筒・便箋ギャラリー</router-link
        >
      </li>
      <li class="mn">
        <router-link
          @click.native="
            scrollToTop();
            $parent.mnvisible = false;
          "
          to="/informations/meetsanta"
          >サンタクロースを呼ぼう！</router-link
        >
      </li>
      <li class="mnhd">お困りですか？</li>
      <li class="">
        <router-link
          @click.native="
            scrollToTop();
            $parent.mnvisible = false;
          "
          to="/informations/qa"
          ><img src="/img/bn_contact.e489dcae.png" alt="お問合せ・Q＆A"
        /></router-link>
      </li>
      <li class="mbnr">
        <a href="https://ultramanmail.com/" target="_blank"
          ><img src="../assets/images/bnr_ul.png" alt=""
        /></a>
        <p>
          【姉妹サイト】
          みんなのヒーロー歴代ウルトラマンからの手紙も好評受付中です
        </p>
      </li>
    </ul>
  </div>
</template>
      
<script>
export default {
  props: [],
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sideContents {
  background: #fffef8;
  padding: 0.3em;
  img {
    max-width: 100%;
  }
  ul {
    list-style: none;
    .green {
      border: solid 1px #ccc;
      border-radius: 0.4em;
      a {
        font-size: 1.2em;
        color: #162345;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1em 0;
        text-decoration: none;
        &:hover {
          background: rgb(244, 253, 246);
        }
      }
    }
    .green a::before,
    .green a::after {
      content: "";
      display: inline-block;
      background: url("../assets/images/icon_green.png") no-repeat center /
        contain;
      width: 1.5em;
      height: 1.5em;
    }
    li.mn a {
      font-size: 0.85em;
      margin-top: 0.7em;
      display: block;
      color: #fff;
      background: #2b3c69;
      padding: 1.3em 0;
      text-align: center;
      text-decoration: none;
      border-radius: 0.4em;
    }
    li.mn a:hover {
      opacity: 0.8;
    }
    li.mnhd {
      margin-top: 1em;
      margin-bottom: 0.8em;
      font-size: 0.9em;
      color: #000000;
    }
    li.mbnr {
      margin-top: 1.5em;
      font-size: 0.8em;
      color: rgb(31, 31, 31);
    }
    li {
    }
  }
}
</style>